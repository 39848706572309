import React from "react";
import "./card.css";
import Logo from "../assets/logo-linktree.jpg";
import PotoGua from "../assets/poto-gua.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from '@mui/icons-material/YouTube';
import TIKTOK from "../assets/icons/tiktok.svg"
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

export default function Card() {
  return (
    <div className="card">
      <div className="card_body">
        <div className="profile">
          <img src={PotoGua} alt="" className="avatar" />
          <h3 style={{ textAlign: "center" }}>dhimasukin</h3>
          <p style={{ textAlign: "center" }}>hehehe</p>
        </div>
         {/* instagram */}
        <div className="button-wrap">
          <a
            href="https://www.instagram.com/dhimasb/"
            target="_blank"
            className="button"
          >
            <InstagramIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Instagram</span>
          </a>
        </div>
        {/* tiktok */}
        <div className="button-wrap">
          <a
            href="https://www.tiktok.com/@dhimasukin/"
            target="_blank"
            className="button"
          >
            <img src={TIKTOK}
              style={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Tiktok</span>
          </a>
        </div>
        {/* youtube */}
        <div className="button-wrap">
          <a
            href="https://www.youtube.com/@dhimasb/"
            target="_blank"
            className="button"
          >
            <YouTubeIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Youtube</span>
          </a>
        </div>
        {/* email */}
        <div className="button-wrap">
          <a
            href="mailto:kerjadhimasukin@gmail.com"
            target="_blank"
            className="button"
          >
            <EmailIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Email</span>
          </a>
        </div>
        <div className="button-wrap">
          <a
            href="https://api.whatsapp.com/send/?phone=6285175209969&text&type=phone_number&app_absent=0"
            target="_blank"
            className="button"
          >
            <WhatsAppIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>WA Endorsement (Jeka)</span>
          </a>
        </div>
        <div className="button-wrap">
          <a href="https://arenvy.com" target="_blank" className="button">
            <DevicesRoundedIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Jasa Pembuatan Website</span>
          </a>
        </div>
        <div className="button-wrap">
          <a href="https://ads.arenvy.com" target="_blank" className="button">
            <GoogleIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Jasa Google Ads</span>
          </a>
        </div>
        {/* <div className="button-wrap">
          <a
            href="https://focksco.com/products"
            target="_blank"
            className="button"
          >
            <ShoppingBagIcon
              sx={{ fontSize: "1.1rem", width: "auto", padding: "0" }}
            />
            <span>Produk Focksco</span>
          </a>
        </div> */}
      </div>
    </div>
  );
}
